<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12 sm6 md8 lg8>
            <span class="heading">Snake List</span>
          </v-flex>
          <v-flex xs12 sm6 md4 lg4 pl-md-2>
            <v-text-field
              outlined
              hide-details clearable
              dense
              placeholder="Search By Name" class="text-des"
              v-model="keyword"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 v-if="items && items.length > 0">
            <v-layout wrap justify-start pt-2>
              <!-- <v-flex xs12 sm6 md6 lg6 class="subheading">
                    <span>Rows per page</span>
                    <v-select
                      v-model="count"
                      color="#427D2D"
                      outlined
                      dense
                      hide-details
                      :items="[20, 30, 50]"
                    ></v-select>
                  </v-flex> -->
              
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12>
                <!-- <v-simple-table> -->
                  <v-data-table
    :headers="computedHeaders"
    :items="items"
    hide-default-footer
    class="elevation-1 text--black"
    id="virtual-scroll-table"
    style="cursor: pointer;"
  >
    <template v-slot:[`item.name`]="{ item }">
      <span v-if="item && item.name">{{ item.name }}</span>
      <span style="color: grey;" v-else>-------</span>
    </template>
    <template v-slot:[`item.scientificName`]="{ item }">
      <span v-if="item && item.scientificName">{{ item.scientificName }}</span>
      <span style="color: grey;" v-else>-------</span>
    </template>
    <template v-slot:[`item.family`]="{ item }">
      <span v-if="item && item.family">{{ item.family }}</span>
      <span style="color: grey;" v-else>-------</span>
    </template>
    <template v-if="mainRole === 'admin'" v-slot:[`item._id`]="{ item }">
      <v-icon small color="success" @click="openviewpage(item)">mdi-eye</v-icon>
      <v-icon small color="primary" class="ml-5" @click="openeditpage(item)">mdi-pencil</v-icon>
      <v-icon small color="red" class="ml-5" @click="opendeleteDialog(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-else pt-16>
            <v-layout
              wrap
              justify-center
              align-center
             
            >
              <v-flex xs12 class="text-center">
                <span class="nodata">No Data Found !</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="items && items.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              circle
              color="#427D2D"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to reject?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="rejectDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="rejectoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
            <script>
import axios from "axios";
export default {
  data() {
    return {
     
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      items: [],
      status: "",
      deleteDialog: false,
      deleteid: "",
      rejectDialog: false,
      rejectid: "",
      // headers: [
      //   { text: "Name", value: "name", width: "100px" },
      //   { text: "Scientific Name", value: "scientificName", width: "100px" },
      //   { text: "Family", value: "family", width: "100px" },
   
      
     
      //   { text: "Actions", value: "_id", width: "20px" }, 
      // ],
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    mainRole() {
      return this.$store.state.userRole;
    },
    computedHeaders() {
      const baseHeaders = [
        { text: 'Name', value: 'name', width: '100px' },
        { text: 'Scientific Name', value: 'scientificName', width: '100px' },
        { text: 'Family', value: 'family', width: '100px' },
      ];

      if (this.mainRole === 'admin') {
        baseHeaders.push({ text: 'Actions', value: '_id', width: '20px' });
      }

      return baseHeaders;
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage=1
    },
  },
  methods: {
    openeditpage(item) {
      this.$router.push({
        path: "/editSnake",
        query: {
          id: item._id,
        },
      });
    },
    openviewpage(item) {
      this.$router.push({
        path: "/snakedetails",
        query: {
          id: item._id,
        },
      });
    },

    opendeleteDialog(item) {
      this.deleteid = item._id;
      this.deleteDialog = true;
    },
    openrejectDialog(item) {
      this.rejectid = item._id;
      this.rejectDialog = true;
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/snake/all/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    deleteoperation() {
      this.appLoading = true;
      axios({
        method: "DELETE",
        url: "/snake/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rejectoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/removedeleterequest",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.rejectid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Rejected Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.rejectDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.rejectDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
            <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>